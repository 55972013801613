* {
    scroll-behavior: smooth;
  }
  
.App{
    min-height: 100vh;
    background-color: rgba(8,29,37);
    color: whitesmoke;
    padding-top: 130px;
    padding-bottom: 70px;
}

.pageTitle {
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 2vw;
    padding: 4px;
    padding-bottom:50px;
    border-radius: 50px;
    color: whitesmoke; 
  }


@media(max-width:700px) {
    .App{
        padding-top: 130px;
    }
}

@media (max-width: 1000px) {
    .pageTitle {
      font-size: 6.4vw;
    }
  }
  