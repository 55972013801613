@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

.ContentModal__landscape {
    object-fit: contain;
    border-radius: 10px;
  }
  
  .ContentModal__portrait {
    display: none;
    object-fit: contain;
    border-radius: 10px;
  }
  
  .tagline {
    padding-bottom: 10px;
    align-self: center;
  }
  
  .ContentModal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  
  .ContentModal::-webkit-scrollbar {
    display: none;
  }
  
  .ContentModal__about {
    padding: 10px;
    width: 95%;
    height: 90%;
    display: flex;
    flex-direction: column;
    font-family: "Roboto", sans-serif;
    justify-content: space-evenly;
    font-weight: 300;
  }
  
  .ContentModal__title {
    height: 12%;
    margin-top: .8rem; /* 4px */
    font-size: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ContentModal__description {
    margin-top: 2rem; 
    display: flex;
    height: 40%;
    overflow-y: scroll;
    padding: 15px;
    border-radius: 50px;
    scrollbar-width: thin; /* Firefox */
    text-align: justify;
  }
  
  .ContentModal__description::-webkit-scrollbar {
    display: none;
  }
  
  @media (min-width: 835px) {
    .ContentModal__landscape {
      display: none;
    }
    .ContentModal__portrait {
      display: flex;
      width: 38%;
    }
    .ContentModal {
      flex-direction: row;
      justify-content: space-around;
      padding: 10px 0;
    }
    .ContentModal__about {
      width: 58%;
      padding: 0;
      height: 100%;
    }
    .ContentModal__title {
      font-size: 3.5vw;
    }
    .ContentModal__description {
      font-size: 22px;
    }
  }